@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.perspective {
  perspective: 2px;
}

.preserve3d {
  transform-style: preserve-3d;
  z-index: -3;
}

.preserve3d-no-z {
  transform-style: preserve-3d;
}

.army-preserve3d {
  transform-style: preserve-3d;
  z-index: 1;
}

.parallax {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(-1px) scale(1.5);
  z-index: -2;
}
.army-parallax {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(0.1px) scale(1);
  z-index: 1;
}

.red-dance-parallax {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(0.5px) scale(0.8);
  z-index: 3;
}

.UA-parallax {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(0.2px) scale(0.9);
  z-index: 1;
  /* background-image: url("./components/photos/UrbanA.jpeg");
  background-size: 100%; */
}

.vsan-parallax {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(0.3px) scale(1);
  z-index: 1;
}

.neg-z-index {
  z-index: -3;
}
